import React, { useEffect, useState, useCallback } from 'react'
import loadable from '@loadable/component'

import { graphql } from 'gatsby'
import { motion } from 'framer-motion'
import { GatsbyImage } from 'gatsby-plugin-image'

import Layout from '@components/common/layout'
import { isMobile } from 'react-device-detect'

import '@styles/syria-health.scss'

const Stories = loadable(() => import('react-insta-stories'))

const ArticleTags = () => {
	return (
		<div className={'px-8 sm:px-0 inverted max-w-6xl flex flex-row justify-start items-center w-full mb-8'}>
			<a href='https://www.thenewhumanitarian.org/conflict' title='View more reports on Conflict' className='p-1 mr-4 font-bold text-gray-50 bg-burgundy' data-report-theme='Conflict'>
				Conflict
			</a>

			<a href='/special-report' title='View more articles of type Special Report' className='mr-4 font-bold text-gray-50 hover:bg-transparent'>
				Special Report
			</a>

			<span className={'text-gray-400'}>8 December 2021</span>
		</div>
	)
}

const Link = (props) => {
	return (
		<a
			target={props.target || '_blank'}
			href={props.href}
			className={`font-bold hover:text-gray-900 ${props.inverted ? 'hover:bg-transparent' : 'hover:bg-gray-50'
				} transition-colors duration-100 pointer-events-auto`}
			style={{ borderBottom: '0px solid white', textDecoration: 'none' }}
		>
			{props.children}
		</a>
	)
}

const AuthorInfo = ({ name, role, imageUrl, twitter }) => {
	return (
		<div className={'px-0 sm:px-0 inverted max-w-6xl flex flex-row justify-start items-center w-full mb-16'}>
			<div
				className={'w-24 h-24 max-h-full'}
				style={{
					background: `url(${imageUrl})`,
					backgroundSize: 'cover',
					minWidth: '6rem',
					minHeight: '6rem',
				}}
			/>
			<div className={'text-white ml-6'}>
				<div className={'flex flex-row'}>
					<Link inverted href='https://www.thenewhumanitarian.org/authors/ammar-azzouz' target='_blank'>
						<h3>{name}</h3>
					</Link>
					<Link href={'https://www.twitter.com/Dr_Ammar_Azzouz'} inverted>
						<span className={'pt-2 w-8 h-8 block'}>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='100%'
								// height='14'
								viewBox='0 0 17 14'
								className={'inline-block ml-2 mr-1'}
							>
								<g fill='none' fillRule='evenodd' stroke='none' strokeWidth='1'>
									<g fill={'#fff'} fillRule='nonzero' transform='translate(0 -1)'>
										<path d='M5.14 15a9.48 9.48 0 009.54-9.54v-.43a6.91 6.91 0 001.67-1.74 6.57 6.57 0 01-1.93.53A3.32 3.32 0 0015.89 2a6.86 6.86 0 01-2.13.81A3.35 3.35 0 008 5.07c0 .26.03.518.09.77a9.51 9.51 0 01-6.95-3.51 3.35 3.35 0 001 4.48 3.38 3.38 0 01-1.48-.42 3.35 3.35 0 002.69 3.33 3.3 3.3 0 01-1.52.06A3.37 3.37 0 005 12.11a6.75 6.75 0 01-4.2 1.43 7 7 0 01-.8 0A9.48 9.48 0 005.14 15'></path>
									</g>
								</g>
							</svg>
						</span>
					</Link>
				</div>
				<p>{role}</p>
			</div>
		</div>
	)
}

const SyriaHealthStory = (props) => {
	const [mobile, setIsMobile] = useState(isMobile)
	const [isPaused, setIsPaused] = useState(true)
	const [currentSlide, setCurrentSlide] = useState(0)

	const Images = props.data.allFile.edges

	const changeSlide = (direction) => {
		if (direction === 'next') {
			if (currentSlide + 1 >= StoriesArray.length) {
				setCurrentSlide(0)
			} else {
				setCurrentSlide(currentSlide + 1)
			}
		} else {
			if (currentSlide - 1 < 0) {
				setCurrentSlide(StoriesArray.length - 1)
			} else {
				setCurrentSlide(currentSlide - 1)
			}
		}
	}

	useEffect(() => {
		setIsMobile(isMobile)
	}, [isMobile])

	const getImageByName = useCallback(
		(name) => {
			return Images.filter((el) => el.node.name === name)[0].node.childImageSharp.gatsbyImageData
		},
		[Images]
	)

	const IMAGE_TITLES_DESKTOP = [
		'',
		'Faces from Syria, 2012',
		'Faces from Syria, 2012',
		'Faces from Syria, 2012',
		'The death of the butterfly, 2007',
		'A mosque in Homs, 2010',
		'Freedom, 2012',
		'Faces from Syria (collage), 2012',
		'Faces from Syria, 2012',
		'Untitled, 2015',
		'Open wound, deep wound, 2015',
		'The scream, 2016',
		'The scream, 2016',
		'A grave for two, 2017',
		'A grave for two, 2017',
		'Khalid Ibn al-Walid Mosque in Homs, around 2017',
		'Untitled, 2018',
	]

	const IMAGE_TITLES_MOBILE = [
		'',
		'',
		'',
		'Faces from Syria, 2012',
		'',
		'',
		'',
		'Faces from Syria, 2012',
		'',
		'The death of the butterfly, 2007',
		'The death of the butterfly, 2007',
		'The death of the butterfly, 2007',
		'A mosque in Homs, 2010',
		'A mosque in Homs, 2010',
		'Freedom, 2012',
		'Freedom, 2012',
		'Freedom, 2012',
		'Faces from Syria (collage), 2012',
		'Faces from Syria (collage), 2012',
		'Faces from Syria (collage), 2012',
		'Faces from Syria (collage), 2012',
		'Faces from Syria, 2012',
		'Faces from Syria, 2012',
		'Faces from Syria, 2012',
		'Untitled, 2015',
		'Untitled, 2015',
		'Untitled, 2015',
		'Open wound, deep wound, 2015',
		'Open wound, deep wound, 2015',
		'Open wound, deep wound, 2015',
		'The scream, 2016',
		'The scream, 2016',
		'The scream, 2016',
		'The scream, 2016',
		'The scream, 2016',
		'The scream, 2016',
		'A grave for two, 2017',
		'A grave for two, 2017',
		'A grave for two, 2017',
		'A grave for two, 2017',
		'Khalid Ibn al-Walid Mosque in Homs, around 2017',
		'Khalid Ibn al-Walid Mosque in Homs, around 2017',
		'Khalid Ibn al-Walid Mosque in Homs, around 2017',
		'Untitled, 2018',
		'Untitled, 2018',
		'Untitled, 2018',
	]

	const SlideImageFull = (props) => {
		return (
			<div
				className={`${
					// props.objectFit === 'contain' ? 'pb-20 sm:pb-0' : ''
					props.objectFit === 'contain' ? 'pb-0' : ''
					} w-full h-full absolute text-white flex items-center justify-center flex-col`}
				style={{
					backgroundColor: props.bgColor || 'transparent',
				}}
			>
				{props.imageName && (
					<GatsbyImage
						image={getImageByName(isMobile ? `mobile-${props.imageName}` : `desktop-${props.imageName}`)}
						className={`absolute top-0 left-0 w-full h-full min-h-full ${
							// props.objectFit === 'contain' ? '-mt-24 sm:-mt-0' : ''
							props.objectFit === 'contain' ? '-mt-0' : ''
							}`}
						imgClassname={'w-full max-w-full'}
						objectFit={props.objectFit || 'cover'}
						alt={''}
					/>
				)}
				<div className={'absolute top-0 left-0 w-full h-full z-50 flex items-center justify-center flex-col p-4'}>{props.children}</div>
			</div>
		)
	}

	const SlideImageLeft = (props) => {
		return (
			<div className={`relative w-full h-screen-mobile sm:h-screen-desktop flex flex-col sm:grid sm:grid-cols-2 responsive-font`}>
				<GatsbyImage
					image={getImageByName(isMobile ? `mobile-${props.imageName}` : `desktop-${props.imageName}`)}
					className={'relative top-0 left-0 h-1/2 sm:w-full sm:h-full'}
					imgClassname={'w-full max-w-full'}
					objectFit={props.objectFit || 'cover'}
					style={{
						height: props.objectFit !== 'cover' ? 'calc(100% - 60px)' : '100%',
					}}
					alt={''}
				/>
				<div className={'flex justify-center pb-20 sm:pb-0 sm:pr-6 items-center flex-col w-full h-screen/2 sm:h-screen-desktop pointer-events-auto'}>
					{props.children}
				</div>
			</div>
		)
	}

	const SlideImageRight = (props) => {
		return (
			<div className={`w-full h-screen-mobile sm:h-screen-desktop flex flex-col sm:grid sm:grid-cols-2 responsive-font`}>
				<div className={'flex justify-center items-center flex-col w-full h-screen-mobile sm:h-screen-desktop'}>{props.children}</div>
				<GatsbyImage
					image={getImageByName(isMobile ? `mobile-${props.imageName}` : `desktop-${props.imageName}`)}
					className={'relative top-0 left-0 h-1/2 sm:w-full sm:h-full'}
					imgClassname={'w-full max-w-full'}
					objectFit={props.objectFit || 'contain'}
					style={{
						height: props.objectFit !== 'cover' ? 'calc(100% - 60px)' : '100%',
					}}
					alt={''}
				/>
			</div>
		)
	}

	const InvertedParagraph = (props) => {
		return (
			<p className={'text-gray-50 inline-block pointer-events-none'} style={{ zIndex: 9999 }}>
				{props.children}
			</p>
		)
	}

	const ImageTitle = (props) => {
		return <h2 className={'text-gray-50 pointer-events-none'}>{props.children}</h2>
	}

	const SlideContent = (props) => {
		return (
			<div
				className={`relative w-full m-0 mb-0 sm:mb-28 flex sm:items-start justify-center flex-col p-5 sm:p-8 flow ${props.className}`}
				style={{
					maxWidth: '100%',
					minHeight: '-webkit-fill-available',
					height: isMobile ? '100vh' : '100%',
					paddingBottom: isMobile ? '70px' : '2rem',
				}}
			>
				{props.children}
			</div>
		)
	}

	const StoriesMobile = [
		{
			content: (storyProps) => {
				storyProps.action('pause')
				return (
					<SlideImageFull imageName={'teaser'}>
						<motion.div
							initial={{
								opacity: 0,
							}}
							animate={{
								opacity: 1,
							}}
							// enter={{
							// 	opacity: 1,
							// }}
							transition={{
								duration: 2,
								delay: 1,
							}}
							className={'w-full h-full text-white flex items-center justify-center flex-col z-50'}
						>
							<div className={'block px-4 p-2 bg-white -mt-10 sm:mt-5 text-gray-900 shadow-xl'}>
								<h1 className={'mb-4'}>Drawing Syria’s trauma</h1>
								<p>‘Drawing became a way of communicating what was happening within.’</p>
								<hr className={'my-3'} />
								<p className={'pt-3 opacity-70 text-center'}>Click the ←left or the right→ side of this section to move through the gallery</p>
							</div>
						</motion.div>
					</SlideImageFull>
				)
			},
		},
		{
			content: (storyProps) => {
				storyProps.action('pause')
				return (
					<SlideContent className={'font-mobile'}>
						<InvertedParagraph>
							Throughout his childhood, Ammar Azzouz found refuge in art. In his adulthood, watching his home country of Syria fall into war from afar, it
							became a true lifeline.
						</InvertedParagraph>
						<InvertedParagraph>
							Azzouz, 33, took art classes during his youth in Homs, a city in western Syria that became known as the “capital of the Syrian revolution” against
							the government of President Bashar al-Assad.
						</InvertedParagraph>
						<InvertedParagraph>
							“In art, I found a refuge, a place to feel secure and safe,” the UK-based architect told The New Humanitarian. “I felt that drawings and paintings
							could tell a story, and had their own language.”
						</InvertedParagraph>
					</SlideContent>
				)
			},
		},
		{
			content: (storyProps) => {
				storyProps.action('pause')
				return (
					<SlideContent>
						<InvertedParagraph>
							Azzouz left Syria in late 2011 for the UK to continue his university education. Violent crackdowns on protesters were already underway and tanks
							were rolling through his neighbourhood, but he thought he would return in a matter of months, or at least years. He still has the Syrian currency
							he brought with him to catch a cab on his next visit home.
						</InvertedParagraph>
					</SlideContent>
				)
			},
		},
		{
			content: (storyProps) => {
				storyProps.action('pause')
				return <SlideImageFull imageName={'intro-2'} objectFit={'cover'} />
			},
		},
		{
			content: (storyProps) => {
				storyProps.action('pause')
				return (
					<SlideContent className={'mobile-font'}>
						<InvertedParagraph>
							Instead, he has seen his parents just once over the past 10 years of war, and he has never been back to Syria. With support systems like family
							and friends far away, he often struggled to process the conflict that was consuming Syria. So he once again turned to art as a way of coping with
							his experiences of trauma, exile, death, and destruction.
						</InvertedParagraph>
					</SlideContent>
				)
			},
		},
		{
			content: (storyProps) => {
				storyProps.action('pause')
				return (
					<SlideContent className={'mobile-font'}>
						x
						<InvertedParagraph>
							After more than a decade of war,{' '}
							<Link href={'https://www.thenewhumanitarian.org/interactive/stories/2021/a-decade-of-war-in-syria/'}>
								at least 13 million Syrians have been forced to flee
							</Link>{' '}
							their homes. Some 6.7 million have been forcibly displaced but remain inside the country, and another 6.6 million are refugees or asylum seekers,
							many in countries near Syria, unsure if they will be able to go home or find safe haven in third countries.
						</InvertedParagraph>
					</SlideContent>
				)
			},
		},
		{
			content: (storyProps) => {
				storyProps.action('pause')
				return (
					<SlideContent>
						<InvertedParagraph>
							Many <Link href={'https://bmcpsychiatry.biomedcentral.com/articles/10.1186/s12888-020-02746-2'}>studies</Link>{' '}
							<Link href={'https://www.nature.com/articles/s41467-020-17369-0.pdf'}>and</Link>{' '}
							<Link href={'https://www.theguardian.com/global-development/2021/mar/01/more-than-75-of-syrian-refugees-may-have-ptsd-says-charity'}>
								surveys
							</Link>{' '}
							have shown that Syrians are at high risk for mental health conditions, including post-traumatic stress disorder (PTSD), depression, and anxiety.
							For those living in refugee camps or still inside Syria, it can be especially difficult to access therapy or other psychological services.
						</InvertedParagraph>
					</SlideContent>
				)
			},
		},
		{
			content: (storyProps) => {
				storyProps.action('pause')
				return <SlideImageFull imageName={'intro-3'} objectFit={'cover'} />
			},
		},
		{
			content: (storyProps) => {
				storyProps.action('pause')
				return (
					<SlideContent className={'mobile-font'}>
						<InvertedParagraph>
							Azzouz was able to get professional help, but sometimes felt he didn’t want to burden others with his problems, especially his family back in
							Syria who were in the midst of chaos. His art – much of which is published here for the first time – became a therapy of sorts. “I was struggling
							to put trauma into words, and drawing became a way of communicating what was happening within.”
						</InvertedParagraph>
						<InvertedParagraph>He describes his artwork in conversations with Middle East Editor Annie Slemrod in the following slides.</InvertedParagraph>
						<InvertedParagraph>(The conversation has been condensed and edited for clarity.)</InvertedParagraph>
					</SlideContent>
				)
			},
		},
		{
			content: (storyProps) => {
				storyProps.action('pause')
				return <SlideImageFull imageName={'dead-butterfly'} objectFit={'contain'} bgColor={'white'} />
			},
		},
		{
			content: (storyProps) => {
				storyProps.action('pause')
				return (
					<SlideContent>
						<ImageTitle>The death of the butterfly, 2007</ImageTitle>
						<InvertedParagraph>
							“This was four years before the start of the Syrian revolution. I was 19, at the start of university. I had a lot of energy and enthusiasm for
							driving change, like a lot of my generation. At that time, there was a feeling that we needed a platform to speak, that our voices were being
							oppressed, talents destroyed… But all our desires for political change, or for cultural initiatives, in a city like Homs, were limited. We were
							struggling to create a space where we could feel free… The butterfly is about freedom, the limits of that freedom.
						</InvertedParagraph>
					</SlideContent>
				)
			},
		},
		{
			content: (storyProps) => {
				storyProps.action('pause')
				return (
					<SlideContent>
						<ImageTitle>The death of the butterfly, 2007</ImageTitle>
						<InvertedParagraph>
							I definitely had a strange feeling at that time of things decaying and things collapsing, and beautiful things being destroyed or killed. The
							subject here is a butterfly, which is, aesthetically speaking, a beautiful thing to look at, a sign of beauty and spring and hope. But it is dead
							and rotten.”
						</InvertedParagraph>
					</SlideContent>
				)
			},
		},
		{
			content: (storyProps) => {
				storyProps.action('pause')
				return (
					<SlideContent bgColor={'rgb(17, 17, 17)'}>
						<SlideImageFull imageName={'mosque-in-homs'} objectFit={'contain'} bgColor={'rgb(17,17,17)'} />
					</SlideContent>
				)
			},
		},
		{
			content: (storyProps) => {
				storyProps.action('pause')
				return (
					<SlideContent>
						<ImageTitle>A mosque in Homs, 2010</ImageTitle>
						<InvertedParagraph>
							“I drew this sometime in 2010, just before the revolution began. It’s a courtyard in a historic mosque in Homs. I had taken a photo once in the
							courtyard, and there were people and birds, and I felt there was so much life there. I drew this before the war, but when I see the images of
							destruction in Syria, this drawing at least is a preservation of that place, which I can no longer return to. It evokes for me a sense of calmness
							and quietness, and a sense of belonging, which I think we need so much. It brings Homs back to my mind.”
						</InvertedParagraph>
					</SlideContent>
				)
			},
		},
		{
			content: (storyProps) => {
				storyProps.action('pause')
				return (
					<SlideContent bgColor={'rgb(17, 17, 17)'}>
						<SlideImageFull imageName={'freedom'} objectFit={'contain'} bgColor={'rgb(17, 17, 17)'} />
					</SlideContent>
				)
			},
		},
		{
			content: (storyProps) => {
				storyProps.action('pause')
				return (
					<SlideContent className={'font-mobile'}>
						<ImageTitle>Freedom, 2012</ImageTitle>
						<InvertedParagraph>
							"This was in January 2012. I had been in the UK for two months, and in Homs there were tanks and so much oppression, and arrests. With this
							drawing, I was imagining what freedom would look like, what freedom would taste like. Drawing this felt like breaking through all the barriers and
							all the oppressive regimes and struggles, and hoping for a better future.
						</InvertedParagraph>
					</SlideContent>
				)
			},
		},
		{
			content: (storyProps) => {
				storyProps.action('pause')
				return (
					<SlideContent className={'font-mobile'}>
						<ImageTitle>Freedom, 2012</ImageTitle>
						<InvertedParagraph>
							I was hopeful, but I was also wondering what would happen next. There was so much crying all the time. Half of my mind was trying to focus on my
							studies, and the other half was following the news and reading, watching what was happening in Syria. It was difficult to be studying at the same
							time that my family was back home and friends were being arrested or killed."
						</InvertedParagraph>
					</SlideContent>
				)
			},
		},
		{
			content: (storyProps) => {
				storyProps.action('pause')
				return <SlideImageFull imageName={'faces-collage'} objectFit={'cover'} bgColor={'white'} />
			},
		},
		{
			content: (storyProps) => {
				storyProps.action('pause')
				return (
					<SlideContent>
						<ImageTitle>Faces from Syria, 2012</ImageTitle>
						<InvertedParagraph>
							“It was my first year living in the UK, and I was on the 15th floor of university accommodation in a tiny bedroom flat. I lived with another
							person who was not from Syria. I was by myself dealing with this war. It was as if I was living in a warzone, but inside the UK.
						</InvertedParagraph>
					</SlideContent>
				)
			},
		},
		{
			content: (storyProps) => {
				storyProps.action('pause')
				return (
					<SlideContent>
						<ImageTitle>Faces from Syria, 2012</ImageTitle>
						<InvertedParagraph>
							I was feeling all these emotions — the psychological trauma, the political oppression — and I wanted to deal with it, document how I was feeling,
							or at least give it a shape or an image. I found myself using a very thin pen, and after I finished I made a second, and then another, and I
							suddenly felt I was making a series. I called them “Faces from Syria.” They deal with questions of expression, freedom, struggle, and
							dictatorship. Whenever I had free time, I would sit down and carry on with the series.
						</InvertedParagraph>
					</SlideContent>
				)
			},
		},
		{
			content: (storyProps) => {
				storyProps.action('pause')
				return (
					<SlideContent>
						<ImageTitle>Faces from Syria, 2012</ImageTitle>
						<InvertedParagraph>
							They were therapeutic for me. With the lines, I was trying to see what’s happening behind the skin, behind the face and the surface. The faces
							almost look like a topographic map or a landscape, or a fingerprint. For me, these lines represent the huge amount of complexity inside all
							Syrians. Wherever we live, we are still impacted by the war.”
						</InvertedParagraph>
					</SlideContent>
				)
			},
		},
		{
			content: (storyProps) => {
				storyProps.action('pause')
				return <SlideImageFull imageName={'faces-from-syria'} objectFit={'cover'} />
			},
		},
		{
			content: (storyProps) => {
				storyProps.action('pause')
				return (
					<SlideContent className={'font-mobile'}>
						<ImageTitle>Faces from Syria, 2012</ImageTitle>
						<InvertedParagraph>
							“This is one of my favourites of the ‘Faces from Syria’ series. As an architect, I see so many ruins of buildings, cities, and towns in Syria, and
							I feel like so many of us are also ruined. I wanted to show that in this face. It’s not just about the physical ruin – so many of us are really
							living with a sense of injury and ruin inside of us. I am physically safe, but there is so much destruction inside of me because of the experience
							of war.
						</InvertedParagraph>
					</SlideContent>
				)
			},
		},
		{
			content: (storyProps) => {
				storyProps.action('pause')
				return (
					<SlideContent className={'font-mobile'}>
						<ImageTitle>Faces from Syria, 2012</ImageTitle>
						<InvertedParagraph>
							This is also the face of a person with much dignity and resilience. I know that there has been so much struggle, but there's still so much
							strength inside the Syrian people. And I found that is also a powerful thing to communicate: a face of pride, and a face of strength.”
						</InvertedParagraph>
					</SlideContent>
				)
			},
		},
		{
			content: (storyProps) => {
				storyProps.action('pause')
				return <SlideImageFull imageName={'untitled'} objectFit={'cover'} />
			},
		},
		{
			content: (storyProps) => {
				storyProps.action('pause')
				return (
					<SlideContent>
						<ImageTitle>Untitled, 2015</ImageTitle>
						<InvertedParagraph>
							“The cross in the background represents crucifixion or a sense of struggle, and like some of my other work, this shows elements of the body beyond
							the surface. There is some optimism here, in that the head is looking towards the sky. This was a way to acknowledge the suffering and trauma, and
							at the same time trying to see some hope beyond all the chaos.
						</InvertedParagraph>
					</SlideContent>
				)
			},
		},
		{
			content: (storyProps) => {
				storyProps.action('pause')
				return (
					<SlideContent>
						<ImageTitle>Untitled, 2015</ImageTitle>
						<InvertedParagraph>
							Often the war in Syria turns into a faceless struggle. By putting a person in the centre of my artwork, I wanted to put the human dimension back
							in.”
						</InvertedParagraph>
					</SlideContent>
				)
			},
		},
		{
			content: (storyProps) => {
				storyProps.action('pause')
				return <SlideImageFull imageName={'open-wound'} objectFit={'cover'} />
			},
		},
		{
			content: (storyProps) => {
				storyProps.action('pause')
				return (
					<SlideContent>
						<ImageTitle>Open wound, deep wound, 2015</ImageTitle>
						<InvertedParagraph>
							“I was in Bath, in western England, doing my PhD when I drew this. I was living in this comfortable city, but I felt the guilt of being away from
							my family. That year, 2015, was the year that so many people were taking boats abroad, and making difficult journeys to walk to Europe. It was a
							year when Syria was in the news on a daily basis. The story that I saw from far away was also one I lived in my exile.
						</InvertedParagraph>
					</SlideContent>
				)
			},
		},
		{
			content: (storyProps) => {
				storyProps.action('pause')
				return (
					<SlideContent>
						<ImageTitle>Open wound, deep wound, 2015</ImageTitle>
						<InvertedParagraph>
							Even though I was living far away, I felt the loss and injury inside me, so I started doing a series of drawings of wounds to show how I felt in
							that moment. We can see the physical scars of wars on buildings and people, they are tangible and visible. We can see the destruction of a city in
							rubble, or people who lost parts of their bodies. But this is the invisible wound that is inside of me. It is open and fresh. I feel that this
							wound, which has been shaped by the last decade of destruction and loss, has still not healed.”
						</InvertedParagraph>
					</SlideContent>
				)
			},
		},
		{
			content: (storyProps) => {
				storyProps.action('pause')
				return <SlideImageFull imageName={'the-scream'} objectFit={'cover'} />
			},
		},
		{
			content: (storyProps) => {
				storyProps.action('pause')
				return (
					<SlideContent>
						<ImageTitle>The scream, 2016</ImageTitle>
						<InvertedParagraph>
							“I was still working on my PhD in Bath when I drew this, and I was dealing with an accumulation of suffering and loss, and feeling that I was
							surrounded by trauma and damage. It was six years into my own exile from Syria, without return, and I was continuing to watch the lines of
							refugees in the news.
						</InvertedParagraph>
					</SlideContent>
				)
			},
		},
		{
			content: (storyProps) => {
				storyProps.action('pause')
				return (
					<SlideContent>
						<ImageTitle>The scream, 2016</ImageTitle>
						<InvertedParagraph>
							I felt isolated and lonely. Bath is a small town that isn’t very diverse, so I couldn’t turn to someone from the Syrian community to talk about
							it. I also felt that no matter how hard one tries to communicate what it means toI felt isolated and lonely. Bath is a small town that isn’t very
							diverse, so I couldn’t turn to someone from the Syrian community to talk about it. I also felt that no matter how hard one tries to communicate
							what it means to come from a warzone, it is difficult to narrate to other people.
						</InvertedParagraph>
					</SlideContent>
				)
			},
		},
		{
			content: (storyProps) => {
				storyProps.action('pause')
				return (
					<SlideContent>
						<ImageTitle>The scream, 2016</ImageTitle>
						<InvertedParagraph>
							At the same time, I didn’t want to burden others with my story, and I didn’t want to be seen as only the person who comes from a warzone, or who
							is always talking about trauma and loss. come from a warzone, it is difficult to narrate to other people. At the same time, I didn’t want to
							burden them with my story, and I didn’t want to be seen as only the person who comes from a warzone, or who always talks about trauma and loss.
						</InvertedParagraph>
					</SlideContent>
				)
			},
		},
		{
			content: (storyProps) => {
				storyProps.action('pause')
				return (
					<SlideContent>
						<ImageTitle>The scream, 2016</ImageTitle>
						<InvertedParagraph>
							I couldn’t always find someone to talk to, or the words to tell what it feels like to walk around feeling damaged, like I’m missing a part of
							myself. But through drawing, I did find a way to articulate my feelings. I didn’t need language to say ‘I feel like I’m bleeding’, or ‘I feel like
							I’m destroyed even though I live in comfort in a rich and beautiful place.’
						</InvertedParagraph>
					</SlideContent>
				)
			},
		},
		{
			content: (storyProps) => {
				storyProps.action('pause')
				return (
					<SlideContent>
						<ImageTitle>The scream, 2016</ImageTitle>
						<InvertedParagraph>
							Many people I know from Syria have no issue at all with seeking professional psychological help in the UK. So all the stigma and concerns and
							stereotypes people might have had in Syria – these are being broken in exile. There’s a much better understanding of the need for it. Many of us
							know how much we have lost in terms of networks of support and care, and are discovering that professional help like talking therapy can make a
							difference.”
						</InvertedParagraph>
					</SlideContent>
				)
			},
		},
		{
			content: (storyProps) => {
				storyProps.action('pause')
				return <SlideImageFull imageName={'a-grave-for-two'} objectFit={'cover'} />
			},
		},
		{
			content: (storyProps) => {
				storyProps.action('pause')
				return (
					<SlideContent>
						<ImageTitle>A grave for two, 2017</ImageTitle>
						<InvertedParagraph>
							“This is about all the people who have lost each other, and all the losses and all the killing in Syria. It was also shaped by the separation of
							so many people: there are now more than six million Syrians living abroad as refugees. We see the people we love from a distance being killed, we
							love them without being able to return and say goodbye to them.
						</InvertedParagraph>
					</SlideContent>
				)
			},
		},
		{
			content: (storyProps) => {
				storyProps.action('pause')
				return (
					<SlideContent className={'mobile-font'}>
						<ImageTitle>A grave for two, 2017</ImageTitle>
						<InvertedParagraph>
							I lost one of my friends in Homs: He was killed on my street in my neighbourhood when there was a bomb targeted at a peaceful demonstration. I
							have friends who were killed in peaceful demonstrations, or in their homes. So, when drawing this, I had flashes in my mind of individuals I have
							lost, people I knew, but there is also a sense of collective loss in Syria. We are not just losing people through death; we are also losing the
							ability to be with the people that we love. We can’t have dinner with the people we love. We are unable to spend these small, domestic, intimate
							moments with our families.
						</InvertedParagraph>
					</SlideContent>
				)
			},
		},
		{
			content: (storyProps) => {
				storyProps.action('pause')
				return (
					<SlideContent className={'mobile-font'}>
						<ImageTitle>A grave for two, 2017</ImageTitle>
						<InvertedParagraph>
							This drawing is about a reunion in a place where people who have lost each other can return to – a place that is imaginary and peaceful. The two
							figures are almost melting inside each other. It’s a different type of place, maybe an alternative future, with a blue sky and a white flower
							between the two figures, showing a more peaceful and hopeful time than this time of darkness. I think there's a tremendous need to remember the
							hope that brought people to the streets in Syria when they demanded an alternative future.
						</InvertedParagraph>
						<InvertedParagraph>I had hurt my finger, and I used some of my own blood in this drawing.”</InvertedParagraph>
					</SlideContent>
				)
			},
		},
		{
			content: (storyProps) => {
				storyProps.action('pause')
				return <SlideImageFull imageName={'khalid-mosque'} objectFit={'contain'} bgColor={'white'} />
			},
		},
		{
			content: (storyProps) => {
				storyProps.action('pause')
				return (
					<SlideContent className={'mobile-font'}>
						<ImageTitle>Khalid Ibn al-Walid mosque in Homs, around 2017</ImageTitle>
						<InvertedParagraph>
							“This is one of the most iconic religious sites in Homs, the Khalid Ibn al-Walid mosque. The drawing is based on a historical photo I found of the
							place before it was transformed in the last few decades.
						</InvertedParagraph>
						<InvertedParagraph>
							By the time I left Homs in 2011, the mosque had already changed a lot – there was a lack of care paid to the landscaping and the greenery. But it
							is also in a part of Homs that was besieged. There were a lot of protests in the area, and the mosque itself was hit during the conflict and
							partially destroyed. For me, this site represents Homs, and the revolution itself.
						</InvertedParagraph>
					</SlideContent>
				)
			},
		},
		{
			content: (storyProps) => {
				storyProps.action('pause')
				return (
					<SlideContent>
						<ImageTitle>Khalid Ibn al-Walid mosque in Homs, around 2017</ImageTitle>
						<InvertedParagraph>
							When I drew this, I was living in London, and very often, being here, I go looking for older, archival materials that show Homs, to see how the
							city looked before. As an architect, I want to try to understand how we can let the past shape our future reconstruction of the city.”
						</InvertedParagraph>
					</SlideContent>
				)
			},
		},
		{
			content: (storyProps) => {
				storyProps.action('pause')
				return (
					<SlideContent>
						<SlideImageFull imageName={'untitled-2'} objectFit={'cover'} />
					</SlideContent>
				)
			},
		},
		{
			content: (storyProps) => {
				storyProps.action('pause')
				return (
					<SlideContent>
						<ImageTitle>Untitled, 2018</ImageTitle>
						<InvertedParagraph>
							“When I drew this, I was living in London and seeing lots of images of chaos in Syria. For people living in a war or with a war, there is so much
							happening in our heads. I wanted to show the complicated feelings of being displaced. So the head is open and shows all the lines and thoughts one
							has. And the face looks damaged, so it is also about inner psychological trauma. It’s a reflection of what is happening inside Syria, but also
							inside myself.
						</InvertedParagraph>
					</SlideContent>
				)
			},
		},
		{
			content: (storyProps) => {
				storyProps.action('pause')
				return (
					<SlideContent>
						<ImageTitle>Untitled, 2018</ImageTitle>
						<InvertedParagraph>
							This drawing came from an ongoing sense of displacement and unsettlement. Even living in the comfort of a city like London, we feel the slow
							violence and the separation from people we love, as we watch our country being destroyed from afar.”
						</InvertedParagraph>
					</SlideContent>
				)
			},
		},
		{
			content: (storyProps) => {
				storyProps.action('pause')
				return (
					<SlideContent bgColor={'rgb(17,17,17)'} styles={{ zIndex: 9999 }}>
						<SlideImageFull>
							<p className={'text-gray-50 font-bold text-center'}>As told to Annie Slemrod</p>
							<p className={'text-gray-50 font-bold text-center'}>–</p>
							<br />
							{/* <CallOut /> */}
						</SlideImageFull>
					</SlideContent>
				)
			},
		},
	]

	const StoriesDesktop = [
		{
			content: (storyProps) => {
				storyProps.action('pause')
				return (
					// <SlideContent bgColor={'transparent'}>
					<SlideImageFull imageName={'teaser'}>
						<motion.div
							initial={{
								opacity: 0,
							}}
							animate={{
								opacity: 1,
							}}
							// enter={{
							// 	opacity: 1,
							// }}
							transition={{
								duration: 3,
								delay: 0.25,
							}}
							className={'absolute top-0 left-0 w-full h-full text-white flex items-center justify-center flex-col z-50'}
						>
							<div className={'block px-12 py-7 bg-white text-gray-900 shadow-xl mb-48'}>
								<h1 className={'mb-4'}>Drawing Syria’s trauma</h1>
								<p className={'font-title-regular'}>‘Drawing became a way of communicating what was happening within.’</p>
								<hr className={'my-3'} />
								<p className={'pt-5 opacity-70 text-center'}>Click the ←left or the right→ side of this section to move through the gallery</p>
							</div>
						</motion.div>
					</SlideImageFull>
					// </SlideContent>
				)
			},
		},
		{
			content: (props) => {
				props.action('pause')
				return (
					<SlideImageLeft imageName={'intro-1'} objectFit={'cover'}>
						<SlideContent>
							{/* <ImageTitle>Faces from Syria</ImageTitle> */}
							<InvertedParagraph>
								Throughout his childhood, Ammar Azzouz found refuge in art. In his adulthood, watching his home country of Syria fall into war from afar, it
								became a true lifeline.
							</InvertedParagraph>
							<InvertedParagraph>
								Azzouz, 33, took art classes during his youth in Homs, a city in western Syria that became known as the “capital of the Syrian revolution”
								against the government of President Bashar al-Assad.
							</InvertedParagraph>
							<InvertedParagraph>
								“In art, I found a refuge, a place to feel secure and safe,” the UK-based architect told The New Humanitarian. “I felt that drawings and
								paintings could tell a story, and had their own language.”
							</InvertedParagraph>
							<InvertedParagraph>
								Azzouz left Syria in late 2011 for the UK to continue his university education. Violent crackdowns on protesters were already underway and tanks
								were rolling through his neighbourhood, but he thought he would return in a matter of months, or at least years. He still has the Syrian
								currency he brought with him to catch a cab on his next visit home.
							</InvertedParagraph>
						</SlideContent>
					</SlideImageLeft>
				)
			},
		},
		{
			content: (props) => {
				props.action('pause')
				return (
					<SlideImageLeft imageName={'intro-2'} objectFit={'cover'}>
						<SlideContent>
							<InvertedParagraph>
								Instead, he has seen his parents just once over the past 10 years of war, and he has never been back to Syria. With support systems like family
								and friends far away, he often struggled to process the conflict that was consuming Syria. So he once again turned to art as a way of coping
								with his experiences of trauma, exile, death, and destruction.
							</InvertedParagraph>
							<InvertedParagraph>
								After more than a decade of war,{' '}
								<Link href={'https://www.thenewhumanitarian.org/interactive/stories/2021/a-decade-of-war-in-syria/'}>
									at least 13 million Syrians have been forced to flee
								</Link>{' '}
								their homes. Some 6.7 million have been forcibly displaced but remain inside the country, and another 6.6 million are refugees or asylum
								seekers, many in countries near Syria, unsure if they will be able to go home or find safe haven in third countries.
							</InvertedParagraph>
							<InvertedParagraph>
								Many <Link href={'https://bmcpsychiatry.biomedcentral.com/articles/10.1186/s12888-020-02746-2'}>studies</Link>{' '}
								<Link href={'https://www.nature.com/articles/s41467-020-17369-0.pdf'}>and</Link>{' '}
								<Link href={'https://www.theguardian.com/global-development/2021/mar/01/more-than-75-of-syrian-refugees-may-have-ptsd-says-charity'}>
									surveys
								</Link>{' '}
								have shown that Syrians are at high risk for mental health conditions, including post-traumatic stress disorder (PTSD), depression, and anxiety.
								For those living in refugee camps or still inside Syria, it can be especially difficult to access therapy or other psychological services.
							</InvertedParagraph>
						</SlideContent>
					</SlideImageLeft>
				)
			},
		},
		{
			content: (props) => {
				props.action('pause')
				return (
					<SlideImageRight imageName={'intro-3'}>
						<SlideContent>
							<InvertedParagraph>
								Azzouz was able to get professional help, but sometimes felt he didn’t want to burden others with his problems, especially his family back in
								Syria who were in the midst of chaos. His art – much of which is published here for the first time – became a therapy of sorts. “I was
								struggling to put trauma into words, and drawing became a way of communicating what was happening within.”
							</InvertedParagraph>
							<InvertedParagraph>He describes his artwork in conversations with Middle East Editor Annie Slemrod below.</InvertedParagraph>
							<InvertedParagraph>(The conversation has been condensed and edited for clarity.)</InvertedParagraph>
						</SlideContent>
					</SlideImageRight>
				)
			},
		},
		{
			content: (props) => {
				props.action('pause')
				return (
					<SlideImageLeft imageName={'dead-butterfly'} objectFit={'cover'}>
						<SlideContent>
							{/* <h2 className={'px-4 py-2 bg-gray-700 mt-5 text-white rounded'}>
                Dead Butterfly, 2017
              </h2> */}
							<ImageTitle>The death of the butterfly, 2007</ImageTitle>
							<InvertedParagraph>
								“This was four years before the start of the Syrian revolution. I was 19, at the start of university. I had a lot of energy and enthusiasm for
								driving change, like a lot of my generation. At that time, there was a feeling that we needed a platform to speak, that our voices were being
								oppressed, talents destroyed… But all our desires for political change, or for cultural initiatives, in a city like Homs, were limited. We were
								struggling to create a space where we could feel free… The butterfly is about freedom, the limits of that freedom.
							</InvertedParagraph>
							<InvertedParagraph>
								I definitely had a strange feeling at that time of things decaying and things collapsing, and beautiful things being destroyed or killed. The
								subject here is a butterfly, which is, aesthetically speaking, a beautiful thing to look at, a sign of beauty and spring and hope. But it is
								dead and rotten.”
							</InvertedParagraph>
						</SlideContent>
					</SlideImageLeft>
				)
			},
		},
		{
			content: (props) => {
				props.action('pause')
				return (
					<SlideImageRight imageName={'mosque-in-homs'}>
						<SlideContent>
							<ImageTitle>A mosque in Homs, 2010</ImageTitle>
							<InvertedParagraph>
								“I drew this sometime in 2010, just before the revolution began. It’s a courtyard in a historic mosque in Homs. I had taken a photo once in the
								courtyard, and there were people and birds, and I felt there was so much life there. I drew this before the war, but when I see the images of
								destruction in Syria, this drawing at least is a preservation of that place, which I can no longer return to. It evokes for me a sense of
								calmness and quietness, and a sense of belonging, which I think we need so much. It brings Homs back to my mind.”
							</InvertedParagraph>
						</SlideContent>
					</SlideImageRight>
				)
			},
		},
		{
			content: (props) => {
				props.action('pause')
				return (
					<SlideImageLeft imageName={'freedom'} objectFit={'contain'}>
						<SlideContent>
							<ImageTitle>Freedom, 2012</ImageTitle>
							<InvertedParagraph>
								"This was in January 2012. I had been in the UK for two months, and in Homs there were tanks and so much oppression, and arrests. With this
								drawing, I was imagining what freedom would look like, what freedom would taste like. Drawing this felt like breaking through all the barriers
								and all the oppressive regimes and struggles, and hoping for a better future.
							</InvertedParagraph>
							<InvertedParagraph>
								I was hopeful, but I was also wondering what would happen next. There was so much crying all the time. Half of my mind was trying to focus on my
								studies, and the other half was following the news and reading, watching what was happening in Syria. It was difficult to be studying at the
								same time that my family was back home and friends were being arrested or killed."
							</InvertedParagraph>
						</SlideContent>
					</SlideImageLeft>
				)
			},
		},
		{
			content: (props) => {
				props.action('pause')
				return (
					<SlideImageRight imageName={'faces-collage'}>
						<SlideContent>
							<ImageTitle>Faces from Syria, 2012</ImageTitle>
							<InvertedParagraph>
								“It was my first year living in the UK, and I was on the 15th floor of university accommodation in a tiny bedroom flat. I lived with another
								person who was not from Syria. I was by myself dealing with this war. It was as if I was living in a warzone, but inside the UK.
							</InvertedParagraph>
							<InvertedParagraph>
								I was feeling all these emotions – the psychological trauma, the political oppression – and I wanted to deal with it, document how I was
								feeling, or at least give it a shape or an image. I found myself using a very thin pen, and after I finished I made a second, and then another,
								and I suddenly felt I was making a series. I called them “Faces from Syria”. They deal with questions of expression, freedom, struggle, and
								dictatorship. Whenever I had free time, I would sit down and carry on with the series.
							</InvertedParagraph>
							<InvertedParagraph>
								They were therapeutic for me. With the lines, I was trying to see what’s happening behind the skin, behind the face and the surface. The faces
								almost look like a topographic map or a landscape, or a fingerprint. For me, these lines represent the huge amount of complexity inside all
								Syrians. Wherever we live, we are still impacted by the war.”
							</InvertedParagraph>
						</SlideContent>
					</SlideImageRight>
				)
			},
		},
		{
			content: (props) => {
				props.action('pause')
				return (
					<SlideImageLeft imageName={'faces-from-syria'}>
						<SlideContent>
							<ImageTitle>Faces from Syria, 2012</ImageTitle>
							<InvertedParagraph>
								"This is one of my favourites of the 'Faces from Syria' series. As an architect, I see so many ruins of buildings, cities, and towns in Syria,
								and I feel like so many of us are also ruined. I wanted to show that in this face. It’s not just about the physical ruin – so many of us are
								really living with a sense of injury and ruin inside of us. I am physically safe, but there is so much destruction inside of me because of the
								experience of war.
							</InvertedParagraph>
							<InvertedParagraph>
								This is also the face of a person with much dignity and resilience. I know that there has been so much struggle, but there's still so much
								strength inside the Syrian people. And I found that is also a powerful thing to communicate: a face of pride, and a face of strength.”
							</InvertedParagraph>
						</SlideContent>
					</SlideImageLeft>
				)
			},
		},
		{
			content: (props) => {
				props.action('pause')
				return (
					<SlideImageRight imageName={'untitled'}>
						<SlideContent>
							<ImageTitle>Untitled, 2015</ImageTitle>
							<InvertedParagraph>
								“The cross in the background represents crucifixion or a sense of struggle, and like some of my other work, this shows elements of the body
								beyond the surface. There is some optimism here, in that the head is looking towards the sky. This was a way to acknowledge the suffering and
								trauma, and at the same time trying to see some hope beyond all the chaos.
							</InvertedParagraph>
							<InvertedParagraph>
								Often the war in Syria turns into a faceless struggle. By putting a person in the centre of my artwork, I wanted to put the human dimension back
								in.”
							</InvertedParagraph>
						</SlideContent>
					</SlideImageRight>
				)
			},
		},
		{
			content: (props) => {
				props.action('pause')
				return (
					<SlideImageLeft imageName={'open-wound'}>
						<SlideContent>
							<ImageTitle>Open wound, deep wound, 2015</ImageTitle>
							<InvertedParagraph>
								“I was in Bath, in western England, doing my PhD when I drew this. I was living in this comfortable city, but I felt the guilt of being away
								from my family. That year, 2015, was the year that so many people were taking boats abroad, and making difficult journeys to walk to Europe. It
								was a year when Syria was in the news on a daily basis. The story that I saw from far away was also one I lived in my exile.
							</InvertedParagraph>
							<InvertedParagraph>
								Even though I was living far away, I felt the loss and injury inside me, so I started doing a series of drawings of wounds to show how I felt in
								that moment. We can see the physical scars of wars on buildings and people, they are tangible and visible. We can see the destruction of a city
								in rubble, or people who lost parts of their bodies. But this is the invisible wound that is inside of me. It is open and fresh. I feel that
								this wound, which has been shaped by the last decade of destruction and loss, has still not healed.”
							</InvertedParagraph>
						</SlideContent>
					</SlideImageLeft>
				)
			},
		},
		{
			content: (props) => {
				props.action('pause')
				return (
					<SlideImageRight imageName={'the-scream'}>
						<SlideContent>
							<ImageTitle>The scream, 2016</ImageTitle>
							<InvertedParagraph>
								“I was still working on my PhD in Bath when I drew this, and I was dealing with an accumulation of suffering and loss, and feeling that I was
								surrounded by trauma and damage. It was six years into my own exile from Syria, without return, and I was continuing to watch the lines of
								refugees in the news.
							</InvertedParagraph>
							<InvertedParagraph>
								I felt isolated and lonely. Bath is a small town that isn’t very diverse, so I couldn’t turn to someone from the Syrian community to talk about
								it. I also felt that no matter how hard one tries to communicate what it means to come from a warzone, it is difficult to narrate to other
								people. At the same time, I didn’t want to burden others with my story, and I didn’t want to be seen as only the person who comes from a
								warzone, or who is always talking about trauma and loss.
							</InvertedParagraph>
						</SlideContent>
					</SlideImageRight>
				)
			},
		},
		{
			content: (props) => {
				props.action('pause')
				return (
					<SlideImageRight imageName={'the-scream'} objectFit={'contain'}>
						<SlideContent>
							<ImageTitle>The scream, 2016</ImageTitle>
							<InvertedParagraph>
								I couldn’t always find someone to talk to, or the words to tell what it feels like to walk around feeling damaged, like I’m missing a part of
								myself. But through drawing, I did find a way to articulate my feelings. I didn’t need language to say ‘I feel like I’m bleeding’, or ‘I feel
								like I’m destroyed even though I live in comfort in a rich and beautiful place.’
							</InvertedParagraph>
							<InvertedParagraph>
								Many people I know from Syria have no issue at all with seeking professional psychological help in the UK. So all the stigma and concerns and
								stereotypes people might have had in Syria – these are being broken in exile. There’s a much better understanding of the need for it. Many of us
								know how much we have lost in terms of networks of support and care, and are discovering that professional help like talking therapy can make a
								difference.”
							</InvertedParagraph>
						</SlideContent>
					</SlideImageRight>
				)
			},
		},
		{
			content: (props) => {
				props.action('pause')
				return (
					<SlideImageLeft imageName={'a-grave-for-two'} objectFit={'contain'}>
						<SlideContent>
							<ImageTitle>A grave for two, 2017</ImageTitle>
							<InvertedParagraph>
								“This is about all the people who have lost each other, and all the losses and all the killing in Syria. It was also shaped by the separation of
								so many people: there are now more than six million Syrians living abroad as refugees. We see the people we love from a distance being killed,
								we love them without being able to return and say goodbye to them.
							</InvertedParagraph>
							<InvertedParagraph>
								I lost one of my friends in Homs: He was killed on my street in my neighbourhood when there was a bomb targeted at a peaceful demonstration. I
								have friends who were killed in peaceful demonstrations, or in their homes. So, when drawing this, I had flashes in my mind of individuals I
								have lost, people I knew, but there is also a sense of collective loss in Syria. We are not just losing people through death; we are also losing
								the ability to be with the people that we love. We can’t have dinner with the people we love. We are unable to spend these small, domestic,
								intimate moments with our families.
							</InvertedParagraph>
						</SlideContent>
					</SlideImageLeft>
				)
			},
		},
		{
			content: (props) => {
				props.action('pause')
				return (
					<SlideImageLeft imageName={'a-grave-for-two'} objectFit={'contain'}>
						<SlideContent>
							<ImageTitle>A grave for two, 2017</ImageTitle>
							<InvertedParagraph>
								This drawing is about a reunion in a place where people who have lost each other can return to – a place that is imaginary and peaceful. The two
								figures are almost melting inside each other. It’s a different type of place, maybe an alternative future, with a blue sky and a white flower
								between the two figures, showing a more peaceful and hopeful time than this time of darkness. I think there's a tremendous need to remember the
								hope that brought people to the streets in Syria when they demanded an alternative future.
							</InvertedParagraph>
							<InvertedParagraph>I had hurt my finger, and I used some of my own blood in this drawing.”</InvertedParagraph>
						</SlideContent>
					</SlideImageLeft>
				)
			},
		},
		{
			content: (props) => {
				props.action('pause')
				return (
					<SlideImageRight imageName={'khalid-mosque'}>
						<SlideContent>
							<ImageTitle>Khalid Ibn al-Walid mosque in Homs, around 2017</ImageTitle>
							<InvertedParagraph>
								“This is one of the most iconic religious sites in Homs, the Khalid Ibn al-Walid mosque. The drawing is based on a historical photo I found of
								the place before it was transformed in the last few decades.
							</InvertedParagraph>
							<InvertedParagraph>
								By the time I left Homs in 2011, the mosque had already changed a lot – there was a lack of care paid to the landscaping and the greenery. But
								it is also in a part of Homs that was besieged. There were a lot of protests in the area, and the mosque itself was hit during the conflict and
								partially destroyed. For me, this site represents Homs, and the revolution itself.
							</InvertedParagraph>
							<InvertedParagraph>
								When I drew this, I was living in London, and very often, being here, I go looking for older, archival materials that show Homs, to see how the
								city looked before. As an architect, I want to try to understand how we can let the past shape our future reconstruction of the city.”
							</InvertedParagraph>
						</SlideContent>
					</SlideImageRight>
				)
			},
		},
		{
			content: (props) => {
				props.action('pause')
				return (
					<SlideImageLeft imageName={'untitled-2'}>
						<SlideContent>
							<ImageTitle>Untitled, 2018</ImageTitle>
							<InvertedParagraph>
								“When I drew this, I was living in London and seeing lots of images of chaos in Syria. For people living in a war or with a war, there is so
								much happening in our heads. I wanted to show the complicated feelings of being displaced. So the head is open and shows all the lines and
								thoughts one has. And the face looks damaged, so it is also about inner psychological trauma. It’s a reflection of what is happening inside
								Syria, but also inside myself.
							</InvertedParagraph>
							<InvertedParagraph>
								This drawing came from an ongoing sense of displacement and unsettlement. Even living in the comfort of a city like London, we feel the slow
								violence and the separation from people we love, as we watch our country being destroyed from afar.”
							</InvertedParagraph>
						</SlideContent>
					</SlideImageLeft>
				)
			},
		},
		{
			content: (storyProps) => {
				storyProps.action('pause')
				return (
					<SlideContent bgColor={'rgb(17, 17, 17)'}>
						<SlideImageFull bgColor={'rgb(17, 17, 17)'}>
							<div className={'w-full h-full text-white flex items-center justify-center flex-col z-50'}>
								<p className={'text-gray-50 font-bold text-center'}>As told to Annie Slemrod</p>
								{/* <CallOut /> */}
							</div>
						</SlideImageFull>
					</SlideContent>
				)
			},
		},
	]

	const StoriesArray = isMobile ? StoriesMobile : StoriesDesktop

	return (
		<Layout
			shareProps={{
				title: 'The New Humanitarian | Drawing Syria’s trauma',
				url: 'https://www.thenewhumanitarian.org/2021/12/08/drawing-syrias-trauma',
				socialTitle: 'The New Humanitarian | Drawing Syria’s trauma',
				socialDescription: 'From Homs to the UK, architect Ammar Azzouz uses art to process the trauma of the Syrian exile experience.',
				socialImage: 'https://images.thenewhumanitarian.org/syria-trauma-teaser.jpg',
				nodeId: 262240,
				updatedTime: '2021-12-08T08:00:00+00:00',
				modiefiedTime: '2021-12-08T08:00:00+00:00',
				publishedTime: '2021-12-08T08:00:00+00:00',
			}}
		>
			<div id='start' className={'syria-health-wrapper'}>
				<div
					className={
						'relative max-w-full h-screen-ios h-screen-mobile sm:h-screen-desktop sm:max-h-screen-desktop overflow-hidden flex items-center justify-center'
					}
				>
					<Stories
						stories={StoriesArray}
						loop
						isPaused={isPaused}
						width={'100%'}
						height={'100%'}
						storyContainerStyles={{
							background: '#282828',
							width: '100%',
							height: '100%',
							position: 'absolute',
						}}
						storyStyles={{
							width: '100%',
							height: '100%',
							objectFit: 'cover',
						}}
						currentIndex={currentSlide}
					/>
					<div
						className={
							'absolute flex flex-row justify-between items-center bottom-0 left-0 right-0 w-full h-20 sm:h-24 bg-gray-900 bg-opacity-50 sm:bg-gradient-to-r sm:from-gray-900 sm:to-gray-900 sm:via-transparent sm:bg-opacity:30 text-white px-3 z-50'
						}
						style={{
							zIndex: 999,
						}}
					>
						<div className={'h-3 absolute -top-3 left-0 bg-gray-200 inline w-full z-30 bg-opacity-80'} />
						<motion.div
							animate={{
								width: currentSlide < 1 ? `2.5%` : `${(currentSlide / (StoriesArray.length - 1)) * 100}%`,
							}}
							transition={{
								duration: 0.15,
								ease: 'easeIn',
							}}
							className={'h-3 absolute -top-3 left-0 bg-burgundy inline w-full z-50'}
							style={{
								width: `${((currentSlide + 1) / StoriesArray.length) * 100}%`,
							}}
						/>
						<button
							className={`px-3 py-1 font-bold cursor-pointer ${currentSlide === 0 ? 'opacity-30 pointer-events-none' : ''}`}
							onClick={() => changeSlide('back')}
						>
							← Back
						</button>
						{IMAGE_TITLES_DESKTOP[currentSlide] && (
							<div
								className={`justiy-center items-center hidden sm:flex flex-row px-5 py-3 font-bold border ${!isPaused ? 'bg-transparent' : 'bg-gray-50 text-gray-900'
									}`}
							>
								{IMAGE_TITLES_DESKTOP[currentSlide]}
							</div>
						)}
						{IMAGE_TITLES_MOBILE[currentSlide] && (
							<div
								className={`justiy-center items-center flex sm:hidden flex-row p-3 font-bold border text-center opacity-80 ${!isPaused ? 'bg-transparent' : 'bg-gray-50 text-gray-900'
									}`}
								style={{
									maxWidth: '160px',
								}}
							>
								{IMAGE_TITLES_MOBILE[currentSlide]}
							</div>
						)}
						<button
							className={`px-3 py-1 font-bold cursor-pointer ${currentSlide >= StoriesArray.length ? 'opacity-30 pointer-events-none' : ''} ${currentSlide === 0 ? 'animate-pulse' : ''
								}`}
							onClick={() => changeSlide('next')}
						>
							{currentSlide + 1 >= StoriesArray.length && 'Restart →'}
							{currentSlide > 0 && currentSlide + 1 < StoriesArray.length && 'Next →'}
							{currentSlide === 0 && 'Start →'}
						</button>
					</div>
					<div
						className={`absolute top-0 left-0 w-full h-full flex flex-row sm:grid sm:grid-cols-2 cursor-pointer ${currentSlide + 1 >= StoriesArray.length ? 'pointer-events-none' : ''
							}`}
						style={{ zIndex: 999 }}
					>
						<div
							// className={'w-full h-full opacity-0 hover:opacity-100 bg-gradient-to-tl from-transparent via-transparent to-gray-50 bg-opacity-5'}
							className={`w-full h-full ${currentSlide === 0 ? 'opacity-30 pointer-events-none hidden' : ''} ${currentSlide + 1 >= StoriesArray.length ? 'hidden' : ''
								}`}
							onClick={() => {
								changeSlide('back')
								// console.log('back')
							}}
						/>
						<div
							// className={'w-full h-full opacity-0 hover:opacity-100 bg-gradient-to-tr from-transparent via-transparent to-gray-50 bg-opacity-5'}
							className={`w-full h-full ${currentSlide === 0 ? 'sm:ml-full' : ''} ${currentSlide + 1 >= StoriesArray.length ? 'hidden' : ''}`}
							onClick={() => {
								changeSlide('next')
								// console.log('next')
							}}
						/>
					</div>
				</div>

				<div className={'bg-gray-900 w-full py-8 sm:py-24 relative flex flex-col justify-center items-center z-50'}>
					<div className={'px-8 sm:px-0 text-left inverted flow max-w-6xl'}>
						<ArticleTags />
						<h2>About the artist</h2>
						<AuthorInfo
							name={'Ammar Azzouz'}
							role={'UK-based architect and writer, analyst at Arup, and Short-Term Research Associate at the University of Oxford.'}
							imageUrl={'https://assets.irinnews.org/s3fs-public/styles/author/public/author.png?DpgMqDTuumPBDm6aJf_9MJRCz9WkAmtQ&itok=FY4qLZCX'}
							twitter={'Dr_Ammar_Azzouz'}
						/>
					</div>
				</div>
			</div>
		</Layout>
	)
}

export default SyriaHealthStory

export const query = graphql`
	query SyriaHealthImages {
		allFile(
			filter: { sourceInstanceName: { eq: "images" }, extension: { in: ["jpg", "png", "jpeg"] }, relativePath: { glob: "stories/2021/syria-health/*" } }
		) {
			edges {
				node {
					extension
					dir
					modifiedTime
					name
					relativePath
					childImageSharp {
						gatsbyImageData(width: 1280, quality: 85, placeholder: BLURRED, layout: FULL_WIDTH)
					}
				}
			}
		}
	}
`
